import React, { Component } from "react";

export class Vblog extends Component {
  render() {
    return (
      <div className="container">
        <div>
          <header className="entry-header">
            <h1 className="entry-title ">A Commitment to Serve Virginia!</h1>
            <div className="entry-meta">
              <strong>
                by -  Eco-Friendly Lawn and Landscaping
                
              </strong>
            </div>
            <hr className="featurette-divider __before_content" />
          </header>

          <p style={{ textAlign: "center" }}>
            <em>
              <strong>
                Let’s make a pledge and join hands to save Virginia from
                Non-source Pollution!
              </strong>
            </em>
          </p>
          <p>Our valued Clients, well-wishers and Friends</p>
          <p>
            <em>Warmest Greetings!</em>
          </p>
          <p>
            It has been three years since the “Eco-Friendly Lawn and
            Landscaping” (ELL) started providing its services in Virginia. In
            the buzzing crowd of landscaping companies, we initially felt lost
            with obvious teething troubles and our inability to grasp the
            nuances of the trade. The road was not strewn with roses but the
            nobility of our mission to revive the “organic splendor” of Virginia
            prodded us to soldier on.
          </p>
          <p>
            Now, we have carved a niche with the only landscaping company that
            provides and campaigns for organic revolution in Virginia. Our
            efforts have been supported by environment-conscious Virginia
            denizens who have started adopting organic measures by discarding
            synthetic chemicals and fertilizers. The warm reception and the
            overwhelming response we got from our valued clients acts as a shot
            of adrenaline to us, thereby giving us the will to excel and
            innovate.
          </p>
          <p>
            Our slogan is: “Think Eco! Live Eco!” As we are all aware of the
            fact that the use of synthetic fertilizers and chemicals negatively
            impacts not only the health of our lawn and landscape but also
            animals, pets, our children and ourselves, it has become extremely
            necessary to adopt organic lifestyle.
          </p>
          <p>
            We have started this blog especially as a tool to provide
            information to our clients in general and the issues of lawn and
            landscaping in particular. We will be sharing our experiences
            through this blog and expect feedback and comments.
          </p>
          <p>With a renewed zeal to make Virginia an organic zone!</p>
          <p>On behalf of ELL Family</p>
          <p>Mahesh Singh Dangol</p>
          <p>Managing Director</p>
          <p>
            <a href="#/Blogs">Back</a>
          </p>
        </div>
      </div>
    );
  }
}

export default Vblog;
