import React, { Component } from "react";

export class Mblog extends Component {
  render() {
    return (
      <div>
        <div className="container">
          <h3 style={{ textAlign: "center" }}>
            <strong>
              <em>Role of Grass Height &amp; Sprinkler System</em>
            </strong>
          </h3>
          <p style={{ textAlign: "justify" }}>
            <strong>By – Mahesh Singh Dangol</strong>
          </p>
          <p style={{ textAlign: "justify" }}>
            The health of our lawn, surprising might it sound for many of us, is
            greatly determined by the height of the grass and the use of the
            sprinkler system. This is particularly true to the organic lawn
            care.
          </p>
          <p style={{ textAlign: "justify" }}>
            According to our experience, a little attention while mowing the
            lawn can help prevent summer weeds from being invasive and protect
            from damages caused by excessive heat, especially during the
            scorching months of June, July and early August.
          </p>
          <p style={{ textAlign: "justify" }}>
            No doubt, it is always best to have a well-provisioned sprinkler
            system in place. In the absence of such a system, it is, however,
            necessary to maintain the height of the grass at around 4 inches
            (this could vary a little depending on the type of grass also but
            generally it is true regarding most types of grasses including “tall
            fescue” which is predominantly used in Northern Virginia ). Tall
            grass is a natural protection against weeds and lack of water
            supply. The grass height is to be maintained long if there is not
            enough rainfall. Exposure of the lawn to direct sunlight during
            summer might invite further damage to your lawn and a 4-inch blade
            mowing practice minimizes the afflictions caused thereupon.
          </p>
          <p style={{ textAlign: "justify" }}>
            Similarly, it is essential to keep the grass height at least 3
            inches during summer (when the heat is scalding and there is no
            regular precipitation) if there is a well-provisioned sprinkler
            system in place. We have come to this conclusion through the
            observation and reporting of our mowing crew in a number of
            properties over the years.
          </p>
          <p style={{ textAlign: "justify" }}>
            Here is an example of Lawn A and Lawn B.
          </p>
          <p style={{ textAlign: "justify" }}>
            We started Lawn A and Lawn B as our organic lawn care program and we
            follow(ed) the same procedures and applications over the course of
            time. However, Lawn B has a sprinkler system in place and Lawn A
            does not. Both lawns are in very good shape during the fall and
            spring. Our mowing crew maintained lawn height to 3” on both
            properties during summer (June and July). However, we got minimal
            summer damage on Lawn B, whereas Lawn A got more damage around the
            area where it gets most sun during the day. In conclusion, the lawn
            needs proper irrigation system from summer damage. If there is not
            better irrigation system then maintaining grass height to at least 4
            inches during June and July will save the lawn from summer damage as
            well as invasion from summer weeds.
          </p>
          <p>
            <a href="#/Blogs">Back</a>
          </p>
        </div>
      </div>
    );
  }
}

export default Mblog;
