import React, { useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import {up} from "./Upload.js";
export default function ContactUs() {
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [message, setmessage] = useState();
  //const [file, setfile] = useState();
  const [upload, setupload] = useState();
  let [loading, setLoading] = useState(false);
  // let [uloading, setuLoading] = useState(false);
  const handleupload = async (e) => {
    //setuLoading(true)
    e.preventDefault();
     
    var formData = new FormData();
    formData.append("File", upload);
    formData.append("bucket", "contactuseco");
    await up(upload,upload.name);
    //setuLoading(false)    
    setupload(null)
    return;
   
  };

  const handleSubmit =async (e) => {
    e.preventDefault();

    setLoading(true);
    // console.log(name);
    // console.log(email);
    // console.log(message);
    // console.log(file);

    // var data = {
    //   email: email,
    //   files: file,
    //   message: message,
    //   replyaddresses: ["mahesh@ecolawnlandscaping.com"],
    // };
   // var formData = new FormData();
   // formData.append("File", file);
   // formData.append("email", email);
   // formData.append("message", message);
   // console.log();
const messagebody={
  email: email,
  message: message,
  name: name

}
console.log(messagebody);

   await axios
      .post(
        "https://3pwx5igx96.execute-api.us-east-1.amazonaws.com/sendemail",
        messagebody,
        // {
        //   headers: { "Content-Type": "multipart/form-data" },
        // }

        //  .post(
        // "http://localhost:4000/sendemailwithattachment",
        // formData,
        // {
        //   headers: { "Content-Type": "multipart/form-data" },
        // }
      )
      .then(function (response) {
        alert("You have successfully contacted Us.");
        setLoading(false);
       // setfile(null)
        setemail(null)
        setmessage(null)
      })
      .catch(function (error) {
        alert("Unsuccesfull!! Something is not right please call us");
        console.log(error);
        setLoading(false);
      });
     
       window.location.reload()
       
    // e.preventDefault();
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <h2 className="display-5">
              Eco-Friendly Lawn and Landscaping, LLC
            </h2>
            <p className="dgr">
              3820 Corona lane, Woodbridge, VA 22193
              <br />
              Woodbridge
              <br />
              Virginia
              <br />
              USA
            </p>
            <p className="dgr">
              Phone: (571) 480-6180
              <br />
              mahesh@ecolawnlandscaping.com
            </p>
            <hr />
            <h2>Please feel free to write to us.</h2>

            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <p className="dgr">
                Name *<br />
                <input
                  defaultValue={name}
                  onChange={(e) => setname(e.target.value)}
                  type="text"
                  className="form-control"
                  name="name"
                />{" "}
              </p>
              <p className="dgr">
                Email *<br />
                <input
                  defaultValue={email}
                  onChange={(e) => setemail(e.target.value)}
                  type="email"
                  className="form-control"
                  name="email"
                />
              </p>
              {/* <p className="dgr">
                Picture (Max size 2MB) <br />
                <input
                  onChange={(e) => setfile(e.target.files[0])}
                  type="file"
                  className="form-control"
                  placeholder="Input any file "
                  name="email"
                  title="any image or video "
                />
              </p> */}
              <p className="dgr">
                Message
                <br />
                <textarea
                  defaultValue={message}
                  onChange={(e) => setmessage(e.target.value)}
                  className="form-control"
                  name="message"
                />{" "}
              </p>

              <p className="dgr">
                <button type="submit" className="btn btn-dark">
                  {loading ? <Loader /> : " Contact us"}
                </button>
              </p>
              <p>*=Required</p>
            </form>
            <div>
              <form
                onSubmit={(e) => {
                  handleupload(e);
                }}
              >
                {/* <p className="dgr">
                  If you have Video or large Image upload here  <br />
                  <input
                    onChange={(e) => setupload(e.target.files[0])}
                    type="file"
                    className="form-control"
                    placeholder="Input any file "
                    name="email"
                    title="any image or video "
                  />
                </p> */}
                {/* <p className="dgr">
                  <button type="submit" className="btn btn-dark">
                     {uloading ? <Loader /> : " Upload "}
                  </button>
                </p> */}
              </form>
            </div>
          </div>

          <div className="col-6">
            <h2>Office Hours</h2>
            <table>
              <tbody>
                <tr>
                  {/* <td style={{ textAlign: 'center' }}><strong>Hours</strong></td> */}
                  <td>
                    <div className="dgr">Mon–Fri: 8:00am–7:00 pm</div>
                    <div className="dgr">Sun: 8:00am–7:00 pm</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h2>Our Location</h2>
            <p>
              <iframe
                title="qtr"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3117.111358886702!2d-77.3299756852728!3d38.62331917065923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b6f886be07ad25%3A0x6190629876584f8a!2s3820+Corona+Ln%2C+Woodbridge%2C+VA+22193!5e0!3m2!1sen!2s!4v1461056297135"
                width={"100%"}
                height={450}
                frameBorder={0}
                allowFullScreen="allowfullscreen"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
