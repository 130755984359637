import React, { Component } from 'react'

export class AboutUs extends Component {
    render() {
        return (
            <div>
                <p className="p-3">
                 <p>                   
                    <img width={"100%"} height={"500px"} src="/images/about.jpg" alt="lawn care" />
                 </p>
                    <p className="display-4 text-success">WE</p>
                    <p>With its slogan <em>&#8220;Think Eco! Live Eco! &#8220;, </em>Eco-Friendly Lawn and Landscaping (ELL) was established with a professed two-pronged motive to:</p>
                    <ol>
                        <li>Cater quality and state-of-the-art lawn and landscaping services in particular;</li>
                        <li>Respond and contribute to rising environmental degradation brought in the wake of non-point source pollution (meaning pollution without an easily identifiable source)</li>
                    </ol>
                    <p>One of the key sources of non-point source pollutants are fertilizers, pesticides and several other synthetic products that we use in maintaining our lawn and landscaping. Numerous studies have shown that children are vulnerable to many lawn and garden Chemicals. &#8220;Parents can make a big difference in their home. They have the power over the decision whether or not to use chemicals,&#8221; says Dr. Philip F. Landrigan of the Mount Sinai School of Medicine.</p>
                    <p>According to <em>Journal of the American Veterinary Medicine Association (April 2004)</em>, certain breed of dogs have been proved to significantly at risk from bladder cancer and a host of other ailments if they are exposed to lawn that are chemically-treated.</p>
                    <p>At this backdrop, we are proud to specialize in some of the newest technologies to cope with some of the sustainable issues such as water pollution, stormwater management, pesticide toxicity, soil health, and fertilizer runoff.</p>
                    <p>We are a full service, eco-friendly, economical and sustainable landscape company. We are committed to create beautiful and healthy landscapes preserving the earth&#8217;s resources.</p>                </p>
            </div>
        )
    }
}

export default AboutUs
