import React from 'react'

function Faq() {
    return (
        <div>
            <div className="container">
                <div><div className="q">What is meant by Organic?</div><div className="a"> “Organic” means landscaping without the application of synthetic pesticides of any kind (insecticides, herbicides, fungicides, etc.) and synthetic fertilizers or soil amendments.</div></div>
                <div><div className="q">What does organic land care means?</div><div className="a">Organic land care is a sustainable ecological landscaping system that promotes and enhances biodiversity, biological nutrient cycles, and soil biological activity. It is based on minimal use of off-­site inputs and on management practices that restore, maintain, and enhance ecological harmony and beauty in urban and suburban landscapes and gardens. </div></div>
                <div><div className="q">What effect do synthetic fertilizers and pesticides on environment?</div><div className="a">The chemicals used in synthetic fertilizers and pesticides destroy the naturally present beneficial organisms in the soil food web. This has a knock-on effect for plants that rely on these organisms to provide a balanced and healthy soil. Without a balanced soil, plants have to rely upon more and more external inputs of chemicals to obtain nutrients and to fend off pests and weeds.</div></div>
                <div><div className="q">Are there any health risks associated with lawn care products?</div><div className="a">There have been many scientific studies regarding negative health consequences of synthetic chemicals found in chemical fertilizers and pesticides </div></div>
                <div><div className="q">Do synthetic fertilizers and pesticides contribute to water pollution?</div><div className="a">Yes. Many states, including Virginia, have directly linked chemical leaching and run off to water pollution.</div></div>
                <div><div className="q">How important is site analysis during initial phase?</div><div className="a">Site Analysis is the primary discipline used to determine appropriate land use––including plant selection and placement, construction and placement of hardscape elements, and in some cases, site modification to create particular ecosystems. Design is the creative application of these principles in the landscape. It employs the disciplines of ecology and sustainability to create landscapes that can be managed organically.</div></div>
                <div><div className="q">Is soil test necessary?</div><div className="a">The foundation of organic land care is knowledge of and proper care for the soil. Soil tests, along with site analysis, allow the land care professional to select and implement practices that maintain or increase soil life and vitality and thereby enable the soil to support a vigorous plant community.</div></div>
                <div><div className="q">Can I put lots of organic fertilizer and soil amendments?</div><div className="a">Fertilizers and soil amendments are tools that enable us to modify existing soil conditions. The “feed the soil” principle is used to benefit plant health, not artificially stimulate plant growth. Unnecessary applications of any fertilizer or soil amendment can cause nutrients to build up to excessive levels in the soil. At such levels, nutrients may enter local water resources. Nitrogen and phosphorus are the nutrients most involved in eutrophication of water bodies (nitrogen in oceans, phosphorus in lakes and ponds), and are thus of major concern as pollutants. Nitrogen can also be a hazard to human health when it pollutes drinking water supplies.</div></div>
                <div><div className="q">What is 'Compost Tea'?</div><div className="a">&#8216;Compost Tea&#8217; is the liquid derived from naturally decomposing organic material. Creating compost tea is usually referred to as &#8220;Brewing&#8221; .We produce our own worm casting for brewing compost tea.</div></div>
                <div><div className="q">How much top dressing can be applied around turf, around perennial, and around shrubs and shade trees?</div><div className="a">On turf: ¼ inch or less, no more than two times per year, for no more than three years, unless a soil test shows organic matter remains below 4% and phosphorus is below “medium” Around perennials: 2 inches or less Around shrubs and shade trees: 3 inches or less.</div></div>
                <div><div className="q">What height should I cut my grass?</div><div className="a">Turf­ type tall fescue and fine fescue grasses are best maintained when a mower cutting height is set at 3 1/2&#8243;. Maintaining grass at this height will help the grass plants through increased photosynthesis and help to crowd out weed seeds that will inevitably land on your lawn. Towards the end of the season (October) lower the cutting height to 2&#8243; to help avoid winter damage. Zoysia grass is best maintained with a mowing height between 2&#8243; and 3&#8243;.</div></div>
                <div><div className="q">Should I leave grass clippings on the grass or remove them?</div><div className="a">Grass clipping should be left on the grass as they quickly breakdown and provide a good source of natural nitrogen to the lawn. It is necessary to have healthy soil for this to be most effective because it is the micro­organisms in the soil that break down the grass clippings and render them into chemical forms that can be readily used by the grass.</div></div>
                <div><div className="q">How long will it take for an organic lawn care program to be effective?</div><div className="a">The length of time is directly related to the intensity of current and past synthetic management practices and overall turf quality. However, by immediately starting to address soil condition and using natural organic fertilizers the transition between a synthetic program and an organic program will be hardly noticeable.</div></div>
            </div>

        </div>
    )
}

export default Faq
