import React, { Component } from 'react';
// import { AmplifySignOut } from '@aws-amplify/ui-react';
// import { Auth } from '@aws-amplify/auth';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

class MainMenu extends Component {

    state = {
        user: []
    }

    render() {
        return (
            <div>
              
                <Navbar  sticky="top" collapseOnSelect expand="lg" bg="light" variant="light">
                
                    <Navbar.Brand href="#/">
                    <img src={'logo.png'} alt="logo" height={'70px'} />
                        {/* <img height="40px" src="/images/fund.png" alt="logo" /> Home */}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                   
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#/">Home</Nav.Link>                            
                            <NavDropdown title="About Us" href="#AboutUs" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#AboutUs">AboutUs</NavDropdown.Item>
                            <NavDropdown.Item href="#Testimonial">Testimonial</NavDropdown.Item>
                               
                                <NavDropdown.Item href="#N"></NavDropdown.Item>                                
                                <NavDropdown.Item href="#action/3.4"></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Our Services" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#/LawnCare">Lawn/Bed Care</NavDropdown.Item>
                                <NavDropdown.Item href="#/LandscapeMaintenance">Landscape Maintenance</NavDropdown.Item>
                                <NavDropdown.Item href="#/LandscapeDesign">Landscape Design</NavDropdown.Item>
                                <NavDropdown.Item href="#/RainGarden">Rain Garden</NavDropdown.Item>
                                <NavDropdown.Item href="#/DrainageDoctor">Drainage Doctor</NavDropdown.Item>
                                <NavDropdown.Item href="#/SnowRemoval">Snow Removal</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Portfolio" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="http://ecolawnlandscaping.com/">Eco lawn</NavDropdown.Item>
                                <NavDropdown.Item href="#email"></NavDropdown.Item>
                                <NavDropdown.Item href="#N"></NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4"></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Blog" id="collasible-nav-dropdown">                              
                                <NavDropdown.Item href="#Blogs">Blogs</NavDropdown.Item>
                                <NavDropdown.Item href="#N"></NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4"></NavDropdown.Item>
                            </NavDropdown>
                            
                                <Nav.Link href="#/Faq">FAQ</Nav.Link>
                           
                            <NavDropdown title="Customer Portal" id="collasible-nav-dropdown">
                            <NavDropdown.Item target="_blank" href="https://portal.ecolawnlandscaping.com">DashBoard</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" href="https://portal.ecolawnlandscaping.com/#/Login">Portal Login</NavDropdown.Item>
                                <NavDropdown.Item target="_blank" href="https://portal.ecolawnlandscaping.com/#/Register">Portal Register</NavDropdown.Item>
                                 <NavDropdown.Item target="_blank" href="http://ecolawnlandscaping.com/">Eco lawn</NavDropdown.Item>
                                <NavDropdown.Item href="#N"></NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4"></NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#/Contactus">Contact Us</Nav.Link>
                        </Nav>
                        <span className="">
                            {/* {
                            this.props.auth.isAuthenticated && this.props.auth.user && (
                                <span>
                                  
                                  
                                Hello {this.props.auth.user.idToken.payload["cognito:username"]}

                                  
                                  
                                </span>
                            )} */}
                        </span>
                        <Nav>
                            {/* {
                                this.state.user &&
                                <AmplifySignOut />
                            } */}
                            {/* {this.props.auth.isAuthenticated && (
                                <Nav.Link href="/" onClick={this.handleLogOut} className="button is-light">
                                    Log out
                                </Nav.Link>
                            )}
                            {!this.props.auth.isAuthenticated && (
                                <Nav>
                                    <Nav.Link href="#Login">Login</Nav.Link>
                                    <Nav.Link href="#Register">Register</Nav.Link>
                                </Nav>
                            )} */}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

            </div>


        );
    }
}

export default MainMenu;
