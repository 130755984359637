import React from 'react'

function LandscapeMaintenance() {
    return (
        <div>
            <div className="jumbotron">
            <p><img width={"100%"} src="/images/Landscape-Maintenance-768x247.jpg" alt="lawn care"/></p>

                <p>
                    We also provide regular and seasonal landscaping services like :
                    regular lawn mowing & edging, pruning of all ornamental plants, hand
                    weeding, aerating and over-seeding,
                    mulching, fertilizing, leaf removal and pre—season clean up.
            </p>
            </div>
        </div>
    )
}

export default LandscapeMaintenance
