import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <div>
        <div>
          <section className="footers bg-light pt-5 pb-3">
            <div className="container pt-5">
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4 footers-one">
                  <div className="footers-logo">
                    <img src={"logo.png"} alt="logo" height={"50px"} />
                  </div>
                  <div className="footers-info mt-3">
                    <p>
                      It has been three years since the “Eco-Friendly Lawn and
                      Landscaping” (ELL) started providing its services in
                      Virginia. In the buzzing crowd of landscaping companies,
                      we initially felt lost with obvious teething troubles and
                      our inability to grasp the nuances of the trade.
                    </p>
                  </div>
                  <div className="social-icons">
                    <a href="https://www.facebook.com/">
                      <i
                        id="social-fb"
                        className="fa fa-facebook-square fa-2x social"
                      />
                    </a>
                    <a href="https://twitter.com/">
                      <i
                        id="social-tw"
                        className="fa fa-twitter-square fa-2x social"
                      />
                    </a>
                    <a href="https://plus.google.com/">
                      <i
                        id="social-gp"
                        className="fa fa-google-plus-square fa-2x social"
                      />
                    </a>
                    <a href="mailto:bootsnipp@gmail.com">
                      <i
                        id="social-em"
                        className="fa fa-envelope-square fa-2x social"
                      />
                    </a>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-2 footers-two">
                  <h5>Services </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#/LawnCare">Lawn/Bed Care</a>
                    </li>
                    <li>
                      <a href="#/LandscapeMaintenance">L. Maintenance</a>
                    </li>
                    <li>
                      <a href="#/LandscapeDesign">Landscape Design</a>
                    </li>
                    <li>
                      <a href="#/RainGarden">Rain Garden</a>
                    </li>
                    <li>
                      <a href="#/DrainageDoctor">Drainage Doctor</a>
                    </li>
                    <li>
                      <a href="#/SnowRemoval">Snow Removal</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-2 footers-three">
                  <h5>Portal </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://portal.ecolawnlandscaping.com/#/Register"
                      >
                        Register Now
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://portal.ecolawnlandscaping.com/#/Login"
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://portal.ecolawnlandscaping.com/#/Dashboard"
                      >
                        Portal
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="http://ecolawnlandscaping.com/"
                      >
                        Eco Site
                      </a>
                    </li>
                    <li>
                      <a href="#/AboutUs">Who are we</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-2 footers-four">
                  <h5>Customer </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#/Testimonial">Testimonial</a>
                    </li>
                    <li>
                      <a href="#/ContactUs">Contact Us</a>
                    </li>
                    <li>
                      <a href="#/FAQ">Q & A</a>
                    </li>
                    <li>
                      <a href="#/Blogs">Blogs</a>
                    </li>
                    <li>
                      <a href="#ContactUs">Feedbacks</a>
                    </li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-2 footers-five">
                  <h5>Social </h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="#/">Facebook</a>
                    </li>
                    <li>
                      <a href="#/">Instagram</a>
                    </li>
                    <li>
                      <a href="#/">Twitter</a>
                    </li>
                    <li>
                      <a href="#/">Google</a>
                    </li>
                    <li>
                      <a href="#/">Linkden</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="disclaimer bg-light border">
            <div className="container">
              <div className="row ">
                <div className="col-md-12 py-2">
                  <small>
                    Disclaimer: The Site may contain testimonials by users of
                    our products and/or services. These testimonials reflect the
                    real-life experiences and opinions of such users. However,
                    the experiences are personal to those particular users, and
                    may not necessarily be representative of all users of our
                    products and/or services. We do not claim, and you should
                    not assume, that all users will have the same experiences.
                    Your individual results may vary.
                    The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public. 
                  </small>
                </div>
              </div>
            </div>
          </section>
          <section className="copyright border">
            <div className="container">
              <div className="row text-center">
                <div className="col-md-12 pt-3">
                  <p className="text-muted">© 2021 Evan Dangol</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Footer;
