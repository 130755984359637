import React, { Component } from 'react'

export class Home extends Component {
    render() {
        return (
            <div>

                <div>
                    <div className="container">
                        <div className="row-fluid">
                            <div className="span12">
                                <h2>Welcome to Eco-Friendly Lawn and Landscaping, LLC</h2>
                                <p><img loading="lazy" alt="imf" src="/images/organic-300x123.png" width={300} height={123} /></p>
                                <p>With its slogan <em>“Think Eco! Live Eco! “, </em>Eco-Friendly Lawn and Landscaping (ELL) was established with a professed two-pronged motive to: a) Respond and contribute to rising environmental degradation brought in the wake of non-point source pollution (meaning pollution without an easily identifiable source) in general; b) Cater quality and state-of-the-art lawn and landscaping services in particular.</p>
                                <p>We are a full service, eco-friendly, economical and sustainable landscape company.  We specialize in some of the newest technologies to cope with 
                                    some of the sustainable issues such as water pollution, stormwater 
                                    management, pesticide toxicity, soil health, and fertilizer runoff. 
                                    <a href="#/AboutUs">Read More</a></p>
                            </div>
                        </div>
                        <hr className="" />
                    </div>
                </div>



























            </div>
        )
    }
}

export default Home
