import S3 from 'react-aws-s3';

export const  up=async (file,name)=>{

const config = {
    bucketName: 'contactuseco',
    dirName: 'media', /* optional */
    region: 'us-east-1',    
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey:process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ,
    s3Url: 'https://contactuseco.s3.amazonaws.com', /* optional */
}

const ReactS3Client = new S3(config);
/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

/* This is optional */
const newFileName = name;

return await ReactS3Client
    .uploadFile(file, newFileName)
    .then(data => {
        if(data.status===204)
        {
            alert("uploaded")
        }
    })
    .catch(err => alert(err))


}
