import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
// { up } from "./Uploadtestimonial.js";
import uuid from "react-uuid";
import Loader from "./Loader";
import "./testimonial.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
export class Testimonial extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      loading: false,
      data: [],
      errors: [],
      testimonials: []
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handlechange = this.handlechange.bind(this);
    this.handlesubmit = this.handlesubmit.bind(this);
    this.listtestimonials = this.listtestimonials.bind(this);
  }
  listtestimonials() {
    // var payload = {
    //   fun: "scan",
    //   TableName: "ecotestimonial",
    // };
    var self = this;
    axios
      .get(
        // "https://rs32218nl9.execute-api.us-east-1.amazonaws.com/dynamo",
        'https://3pwx5igx96.execute-api.us-east-1.amazonaws.com/testimonial',
        //payload
      )
      .then(function (response) {
        var x = response.data.data.Items;
        //console.log(x);
        x.forEach(element => {
          //if(!element.photo)
          //{
          element.photo = "/images/nopic.png"
          //}
        });
        self.setState({ testimonials: x });
      })
      .catch(function (error) {
        alert("Unsuccesfull!! Something is not right", error);
      });
  }
  componentDidMount() {
    this.listtestimonials()
  }

  handleClose = () => {
    this.setState({ show: false });

  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handlechange(evt) {
    evt.preventDefault();
    var fname = evt.target.name;
    var fvalue = "";

    if (fname === "image") {
      fvalue = evt.target.files[0];
    } else {
      fvalue = evt.target.value;
    }
    let errors = this.state.errors;
    var d = {
      ...this.state.data,
      [fname]: fvalue,
    };
    var sc = "";
    switch (fname) {
      case "type":
        errors.type = fvalue.length < 1 ? "Enter Type" : "";
        break;

      case "passwordqq":
        errors.password =
          fvalue.length < 8
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      case "price":
        d.price = parseFloat(d.price);
        break;
      case "quantityPurchased":
        d.quantityPurchased = parseInt(d.quantityPurchased);
        break;
      case "quantityRemaining":
        d.quantityRemaining = parseInt(d.quantityRemaining);
        break;
      case "quantity":
        d.quantity = parseInt(d.quantity);
        break;
      case "usedDate":
        var dateu = Date.parse(d.usedDate);
        if (!this.checkdate(dateu)) {
          errors.usedDate = "invalid used date";
        }
        console.log(errors);
        break;
      case "purchasedDate":
        var date = Date.parse(d.usedDate);
        if (!this.checkdate(date)) {
          errors.usedDate = "invalid used date";
        }
        console.log(errors);
        break;
      case "site":
        sc = this.resolvesite(evt.target.value);
        if (sc.length > 0) {
          d.siteCategory = sc;
          this.setState({ siteCategory: sc });
        }
        break;
      default:
        break;
    }

    this.setState({ errors, [fname]: fvalue });
    //console.log(d);
    this.setState({ data: d });
  }
  async handlesubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    // formData.append("File", file);
    //console.log(this.state.data);
    // let p = null;
    // for (let key in this.state.data) {
    //   if (key === "image") {
    //     p = this.state.data[key];
    //   }
    // }
    //console.log(photo);
    // var d=[];
    // if(p)
    // {
    // d = await up(p, p.name);
    // }

    //delete this.state.data.image;

    let photo = this.state.data.image;
    //console.log(photo.name);

    let id = uuid();
    let Item = { id, ...this.state.data, photo };

    this.state.data = { ...Item };

    var payload = {
      fun: "put",
      TableName: "ecotestimonial",
      Item,
    };
    //console.log(payload);

    axios
      .post(
        "https://3pwx5igx96.execute-api.us-east-1.amazonaws.com/testimonial",
        payload
      )
      .then(function (response) {
        alert("success");
      })
      .catch(function (error) {
        alert("Unsuccesfull!! Something is not right please call us");
      });
    this.setState({ loading: false });
    this.setState({ show: false });
    this.listtestimonials();
  }
  render() {
    return (
      <div className="container">
        <div className="card m-2">
          <Button
            className="align-content-center"
            variant="primary"
            onClick={this.handleShow}
          >
            Add testimonial
          </Button>
        </div>

        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Fill The Testimonial(coming soon)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form encType="multipart/form-data">
              <div className="row-fluid">
                <div>
                  <p>
                    Name *<br />
                    <span>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="your-name"
                        name="name"
                        onChange={this.handlechange}
                      />
                    </span>{" "}
                  </p>
                </div>
                <div className="span6">
                  <p>
                    Email *<br />
                    <span>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="your-email"
                        onChange={this.handlechange}
                      />
                    </span>{" "}
                  </p>
                </div>
              </div>
              <div className="row-fluid">
                <div className="span6">
                  <p>
                    Location (Eg : Roppongi, Tokyo) *<br />
                    <span>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="location"
                        name="location"
                        onChange={this.handlechange}
                      />
                    </span>
                  </p>
                </div>
                <div className="span6">
                  <p>
                    Website (If Any) <br />
                    <span>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="website"
                        name="website"
                        onChange={this.handlechange}
                      />
                    </span>
                  </p>
                </div>
              </div>
              <p>
                Image (If Any) <br />
                <span>
                  <input
                    className="form-control"
                    type="file"
                    name="image"
                    placeholder="upload your photo"
                    size={40}
                    accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.ppt,.pptx,.odt,.avi,.ogg,.m4a,.mov,.mp3,.mp4,.mpg,.wav,.wmv"
                    aria-invalid="false"
                    onChange={this.handlechange}
                  />
                </span>
              </p>
              <p>
                Testimonial *<br />
                <span>
                  <textarea
                    className="form-control"
                    cols={40}
                    rows={10}
                    name="testimonial"
                    onChange={this.handlechange}
                  />
                </span>
              </p>
              <p>
                About You in less than 10 words. (Eg: CEO Dream Ltd) *<br />
                <span>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="About you"
                    name="about"
                    onChange={this.handlechange}
                  />
                </span>
              </p>

              <p>
                {this.state.loading ? (
                  <Loader />
                ) : (
                  <input
                    type="submit"
                    className="btn btn-dark"
                    defaultValue="Send"
                    onClick={this.handlesubmit}
                  />
                )}
              </p>
              <p>*=Required</p>
              <div aria-hidden="true" />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={6100}
          >
            {this.state.testimonials &&
              this.state.testimonials.map((data, key) => (
                <div key={data.id}>
                  <img alt="i" src={data.photo} />
                  <div className="myCarousel">
                    <h3>{data.name}</h3>
                    <h4>{data.about}</h4>
                    <p>
                      {data.testimonial}
                    </p>
                  </div>
                </div>
              ))}
            <div>
              <img alt="i" src="/images/shirley.png" />
              <div className="myCarousel">
                <h3>Shirley Fultz</h3>
                <h4>Designer</h4>
                <p>
                  It's freeing to be able to catch up on customized news and
                  not be distracted by a social media element on the same
                  site
                </p>
              </div>
            </div>
            <div>
              <img alt="i" src="/images/daniel.png" />
              <div className="myCarousel">
                <h3>Daniel Keystone</h3>
                <h4>Designer</h4>
                <p>
                  The simple and intuitive design makes it easy for me use. I
                  highly recommend Fetch to my peers.
                </p>
              </div>
            </div>

            <div>
              <img alt="i" src="/images/theo.png" />
              <div className="myCarousel">
                <h3>Theo Sorel</h3>
                <h4>Designer</h4>
                <p>
                  I enjoy catching up with Fetch on my laptop, or on my phone
                  when I'm on the go!
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Testimonial;
