import React, { Component } from "react";

export class Blogs extends Component {
  render() {
    return (
      <div>
        <div className="container card">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <a href="#/Mblog" className="list-group-item list-group-item-action">A Commitment to Serve Virginia!</a>
            </li>
            <li className="list-group-item">
              <a href="#/Vblog" className="list-group-item list-group-item-action">Keeping Weeds at Bay during Summer</a>
            </li>
           
          </ul>
        </div>
      </div>
    );
  }
}

export default Blogs;
