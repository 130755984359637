import React from 'react'

function RainGarden() {
    return (
        <div>
            <div className="jumbotron">
            <p><img width={"100%"} src="/images/Rain-Garden-768x247.jpg" alt="lawn care"/></p>

                <p>Rain Garden is an important tool to address the environmental concerns raised in the wake of increased stormwater runoff due to urbanization which causes flooding, sweeps away pollutants and have negative repercussions on stormwater treatment.</p>
                <p>Rain Garden facilitates soaking of more than 30 % water as compared to the traditional lawns. It checks flooding and drainage. It adds to the beauty of your property.</p>
                <ul>
                    <li>It is not a breeding ground for mosquitoes like lawns without proper stormwater drainage system in place – it attracts birds and dragon flies, the mosquito eaters, instead.</li>
                    <li>No maintenance hassles – occasional weeding and watering will suffice</li>
                </ul>
            </div>
        </div>
    )
}

export default RainGarden
