import React from 'react'

function LandscapeDesign() {
    return (
        <div>
            <div className="jumbotron">
            <p><img width={"100%"} src="/images/Landscape-Design-768x247.jpg" alt="lawn care"/></p>

            <p>As per you needs and size of your property, we execute ingenious hardscaping and innovative finishing touches. Our services include, but not limited to:</p>
            <ul>
                <li>Start up front</li>
                <li>Befitting plants at appropriate places (Texture/Shape)</li>
                <li>Outdoor lightening ideas and designs</li>
                <li>Hardscaping (Sidewalk, walkaway, patio, driveway, parking areas, side yards and dog&#8217;s run and arbor)</li>
                <li>Options to Grass/Patches (Special plants like Creeping speedwell, Roman Chamomile, Irish Moss etc. as an alternative to grass)</li>
                <li>Brushstrokes ( artifacts, containers, window boxes, water features, (hanging) baskets, statues as per personal taste of our clients)</li>
                <li>Regular Maintenance</li>
            </ul>
            <p><strong><em>We also help our clients to design their landscape as per the ancient wisdom of Indian Vastu Shastra &amp; Chinese Feng Shui for good luck, positive vibrations and prosperity. </em></strong></p>
            </div>
        </div>
    )
}

export default LandscapeDesign
