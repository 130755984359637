import MainMenu from "./components/MainMenu";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import Testimonial from "./components/Testimonial";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import LawnCare from "./components/Services/LawnCare";
import LandscapeDesign from "./components/Services/LandscapeDesign";
import LandscapeMaintenance from "./components/Services/LandscapeMaintenance";
import RainGarden from "./components/Services/RainGarden";
import SnowRemoval from "./components/Services/SnowRemoval";
import Faq from "./components/Faq";
import ContactUs from "./components/ContactUs";
import DrainageDoctor from "./components/Services/DrainageDoctor";
import Blogs from "./blogs/Blogs";
import Mblog from "./blogs/Mblog";
import Vblog from "./blogs/Vblog";

import "./App.css";

function App() {
  //console.log(process.env.REACT_APP_AWS_ACCESS_KEY_ID);
  // console.log(process.env.REACT_APP_AWS_SECRET_ACCESS_KEY);

  const authProps = {};
  return (
    <div className="App container-fluid ">
      <header className="App-header">
        <MainMenu />
      </header>
      <div className="phone">Call Us: (571) 480-6180</div>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Home {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/Testimonial"
            render={(props) => <Testimonial {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/Aboutus"
            render={(props) => <AboutUs {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/LawnCare"
            render={(props) => <LawnCare {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/LandscapeDesign"
            render={(props) => <LandscapeDesign {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/LandscapeMaintenance"
            render={(props) => (
              <LandscapeMaintenance {...props} auth={authProps} />
            )}
          />
          <Route
            exact
            path="/RainGarden"
            render={(props) => <RainGarden {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/SnowRemoval"
            render={(props) => <SnowRemoval {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/Faq"
            render={(props) => <Faq {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/ContactUs"
            render={(props) => <ContactUs {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/DrainageDoctor"
            render={(props) => <DrainageDoctor {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/Blogs"
            render={(props) => <Blogs {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/Mblog"
            render={(props) => <Mblog {...props} auth={authProps} />}
          />
          <Route
            exact
            path="/Vblog"
            render={(props) => <Vblog {...props} auth={authProps} />}
          />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
