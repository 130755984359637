import React from 'react';

const SnowRemoval = () => {
    return (
        <div>
             <div className="jumbotron">
             <p><img width={"100%"} src="/images/Snow-Removal-1-768x247.jpg" alt="lawn care"/></p>

            We also provide snow removal services to our regular clients.
        </div>
        </div>
    );
}

export default SnowRemoval;
