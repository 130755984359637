import React from 'react'

function LawnCare() {
    return (
        <div>
            <div className="jumbotron">
                <p><img width={"100%"} src="/images/Lawn-Bed-Care-768x247.jpg" alt="lawn care"/></p>
                <h2>We predominantly cater:</h2>
                <ol className="lc">
                    <li>Detox Eden Program (A Comprehensive Organic Program)<br />
                        This program caters a long-term engagement with our clients to revive the natural splendor of lawn/bed deteriorated by inorganic treatment or maintenance. We discuss our clients and explore collaborative possibilities about the range of services we cater and beyond.</li>
                    <li>Verdant Eden program (Complete Organic)<br />
                        This program is complete organic program basically focused on providing complete organic solutions to our clients. This program can be, with the consent of our clients, upgraded to the &#8220;Detox Eden Program&#8221;.</li>
                    <li>Healthy Eden Program (Half-organic)<br />
                        This program is for our clients who prefer chemicals to organic treatment. Although we encourage adopting organic solutions, we provide this service using minimal of chemicals and as per the set standards.</li>
                </ol>
                <p>Please see <a href="#/FAQ">FAQ&#8217;s</a> about Organic program.</p>
                <hr />
            </div>
        </div>
    )
}

export default LawnCare
